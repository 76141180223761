// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification_notification-page__F2bP1{font-size:20px;display:flex;flex-direction:column;justify-content:center;align-items:center;height:100vh}.notification_notification-page__F2bP1 .notification_notification-link-message__GBuu5{font-size:15px}`, "",{"version":3,"sources":["webpack://./src/pages/notification/index.module.scss"],"names":[],"mappings":"AAAA,uCACE,cAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,YAAA,CAEA,sFACE,cAAA","sourcesContent":[".notification-page {\n  font-size: 20px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n\n  .notification-link-message {\n    font-size: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notification-page": `notification_notification-page__F2bP1`,
	"notification-link-message": `notification_notification-link-message__GBuu5`
};
export default ___CSS_LOADER_EXPORT___;
