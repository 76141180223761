import { fetchAxiosAdaptor } from './configuration-axios'

export const ApproverApi = async(token: string) => {
  try {
    return await fetchAxiosAdaptor({
      path: `/approval/approve/${token}`,
      method: 'GET'
    })
  } catch (e: any) {
    throw new Error(e.response.data.message)
  }
}
