
import { fetchAxiosAdaptor } from './configuration-axios'

export const RejectTemplateApi = async(token: string) => {
  try {
    const result = await fetchAxiosAdaptor({
      path: `/approval/deny/${token}`,
      method: 'GET'
    })
    return result
  } catch (e: any){
    throw new Error(e.response.data.message)
  }
}
