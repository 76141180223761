import axios, { type AxiosResponse } from 'axios'
import { getOktaToken } from '../hooks/oktaHooks'

async function fetchAxiosAdaptor({
  path,
  data,
  method,
  notificationFeed = false,
}: {
  path: string
  data?: string
  method: string
  notificationFeed?: boolean
}): Promise<AxiosResponse<any, any>> {
  const baseUrl = notificationFeed
    ? process.env.REACT_APP_FEED_API_URL
    : process.env.REACT_APP_API_URL
  const token: string = getOktaToken()
  return await axios({
    method,
    url: baseUrl + path,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export { fetchAxiosAdaptor }
