import React from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'
import App from './router'

console.log('Notification Application')
const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  errorBoundary(_err, _info, _props) {
    return (
      <div>
        ERROR Something Went Wrong! | NOTIFICATION APP
      </div>
    )
  },
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = [(props: any) => reactLifecycles.mount(props)]
export const unmount = [(props: any) => reactLifecycles.unmount(props)]
