import { useEffect } from 'react'
import { NavManager } from '@amgen/rtsensing-nav'

/* one navManager instance per site */
let navManager: NavManager | undefined
if (NavManager) {
  navManager = new NavManager('External')
}

export function useNavManager (permissions: any, hideMainNavBar: boolean, hideSideNavBar: boolean) {
  useEffect(() => {
    if (!navManager) {
      return
    }

    let newSections: any = {}

    newSections = {
      metaData: { hideMainNavBar, hideSideNavBar }
    }

    navManager.setNavLinkSections(newSections).catch(() => {})
  }, [permissions])
}
