import btnClassNames from './index.module.scss'
import { Button } from '@gitlab-rtsensing/component-library'
interface PrimarySubscriberBtnType {
  title: string
  onClick: () => void
}
const PrimarySubscriberBtn: React.FC<PrimarySubscriberBtnType> = ({
  title,
  onClick,
}: PrimarySubscriberBtnType) => {
  return (
    <Button
      type="primary"
      label={title}
      onClick={onClick}
      className={btnClassNames['notify-subscriber-btn']}
    />
  )
}
export default PrimarySubscriberBtn
