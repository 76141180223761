import React from 'react'
import NotificationPageStyles from './index.module.scss'

interface NotificationTypes {
  renderedText?: string
}
function Notification(props: NotificationTypes) {
  return (
    <div className={NotificationPageStyles['notification-page']}>
      Notification Page
      <br />
      <br />
      <div className={NotificationPageStyles['notification-link-message']}>
        Welcome to Notification Sensing! <b>{props.renderedText}</b>
      </div>
    </div>
  )
}

export default Notification
