import { OktaAuth } from '@okta/okta-auth-js'

const issuer = process.env.REACT_APP_OKTA_ISSUER ?? ''
const clientId = process.env.REACT_APP_OKTA_CLIENT_ID ?? ''
const redirectUri = window.location.origin + '/implicit/callback'

export const NotificationOktaConfig = new OktaAuth({
  issuer,
  clientId,
  redirectUri,
  scopes: ['openid', 'profile', 'email', 'offline_access'],
  pkce: true,
  responseType: 'code',
  tokenManager: {
    expireEarlySeconds: 120,
    autoRenew: true,
    storageKey: 'sensing-okta-token',
  },
})
