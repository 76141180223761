/* eslint-disable n/no-callback-literal */
import { Loader } from '@gitlab-rtsensing/component-library'
import style from './index.module.scss'
import { useParams, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { ApproverApi, RejectTemplateApi } from '../api'
import PrimarySubscriberBtn from '../component/subscriber-btn'

interface ApproverOAuthType {
  isApproved: boolean
  cb: (prop: any) => void
}

const ApproverOAuth: React.FC<ApproverOAuthType> = ({ isApproved, cb }) => {
  const navigation = useNavigate()
  const params: any = useParams()
  const [respMessage, setRespMessage] = useState('')
  const callApproverApi = async() => {
    try {
      if (isApproved && params.approverToken) {
        const response = await ApproverApi(params.approverToken)
        setRespMessage(response.data.message)
      }
      if (!isApproved && params.deniedToken) {
        const response = await RejectTemplateApi(params.deniedToken)
        console.log('error', response)
        setRespMessage(response.data.message)
      }
    } catch (e: any){
      setRespMessage(e.message)
    }
  }
  useEffect(() => {
    callApproverApi()
  }, [params])
  return (
        <div className={style['approver-wrapper'] }>
            {!respMessage
              ? <Loader />
              : <div
              className={style['approver-wrapper-child']}>
                <div dangerouslySetInnerHTML={{ __html: respMessage }}/>
              <div className={style['approver-button-wrapper']}>
              <PrimarySubscriberBtn onClick={() => {
                cb({ showMenu: true })
                navigation('/')
              }} title='Go To Home' />
            </div>
              </div>
            }
        </div>
  )
}
export default ApproverOAuth
